// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-tree-jsx": () => import("/opt/build/repo/src/templates/tree.jsx" /* webpackChunkName: "component---src-templates-tree-jsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nostalgia-index-js": () => import("/opt/build/repo/src/pages/nostalgia/index.js" /* webpackChunkName: "component---src-pages-nostalgia-index-js" */),
  "component---src-pages-travels-index-js": () => import("/opt/build/repo/src/pages/travels/index.js" /* webpackChunkName: "component---src-pages-travels-index-js" */),
  "component---src-pages-writing-index-js": () => import("/opt/build/repo/src/pages/writing/index.js" /* webpackChunkName: "component---src-pages-writing-index-js" */)
}

